<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("DispatcherList.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fa-user-cog</v-icon>
    </v-toolbar>

    <v-btn small class="my-2" color="success" @click.stop="openCreateDialog()">
      {{ $t("btnCreate") }}
    </v-btn>

    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      class="elevation-1"
      sort-by="lastName"
      :mobile-breakpoint="mobileBreakpointTable"
      dense
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, -1],
        showFirstLastPage: true,
      }"
    >
      <!-- Filtrovanie tabulky -->
      <template
        v-slot:[`body.prepend`]
        v-if="$vuetify.breakpoint.width > mobileBreakpointTable"
      >
        <tr>
          <td>
            <v-icon class="px-3">mdi-filter</v-icon>
          </td>
          <td></td>
          <td>
            <v-text-field
              v-model="filter.email"
              type="text"
              clearable
              :label="$t('DispatcherList.filterLabel.email')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.firstName"
              type="text"
              clearable
              :label="$t('DispatcherList.filterLabel.firstName')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.lastName"
              type="text"
              clearable
              :label="$t('DispatcherList.filterLabel.lastName')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.phone"
              type="text"
              clearable
              :label="$t('DispatcherList.filterLabel.phone')"
            ></v-text-field>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <template v-slot:[`body.prepend`] v-else>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.email"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DispatcherList.filterLabel.email')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.firstName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DispatcherList.filterLabel.firstName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.lastName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DispatcherList.filterLabel.lastName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.phone"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DispatcherList.filterLabel.phone')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.activeAcc`]="{ item }">
        <v-simple-checkbox
          v-model="item.activeAcc"
          disabled
        ></v-simple-checkbox>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        <v-chip small dark label>
          {{ $t("DispatcherRole." + item.role) }}
        </v-chip>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon small @click="detail(item.id)">fa-eye</v-icon>-->
        <!-- <v-icon small @click="deleteTutorial(item.id)">mdi-delete</v-icon> -->
        <v-icon
          small
          class="ml-2"
          @click="openEditDialog(item.id)"
          :title="$t('DispatcherList.editInformation')"
          >mdi-square-edit-outline</v-icon
        >
        <v-icon
          v-show="displayOnlyForAdmin"
          small
          class="ml-2"
          @click="openEditPasswordDialog(item.id)"
          :title="$t('DispatcherList.changePassword')"
          >mdi-lock-reset</v-icon
        >
        <v-icon
          v-show="displayOnlyForAdmin || displayOnlyForOwner"
          small
          class="ml-2"
          @click="openEditRoleDialog(item.id)"
          :title="$t('DispatcherList.changeRole')"
          >mdi-key</v-icon
        >
      </template>
    </v-data-table>

    <v-dialog
      v-model="showCreateDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("DispatcherList.dialogCreateTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('DispatcherList.email')"
                    filled
                    dense
                    prepend-inner-icon="fa-envelope"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.email"
                    required
                    ref="emailField"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DispatcherList.password')"
                    prepend-inner-icon="fa-lock"
                    filled
                    :rules="passwordRules"
                    v-model="createDialogData.item.password"
                    required
                    :append-icon="
                      createDialogData.showPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :type="createDialogData.showPassword ? 'text' : 'password'"
                    append-outer-icon="fa-random"
                    @click:append="
                      createDialogData.showPassword =
                        !createDialogData.showPassword
                    "
                    @click:append-outer="
                      createDialogData.item.password =
                        Math.random().toString(36).substr(2, 6) +
                        Math.random().toString(36).substr(2, 6);
                      createDialogData.showPassword = true;
                    "
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DispatcherList.firstName')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.firstName"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DispatcherList.lastName')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.lastName"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    prepend-inner-icon="fa-phone"
                    :label="$t('DispatcherList.phone')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.phone"
                    :placeholder="$t('phone_placeholder')"
                    :hint="$t('phone_hint')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="showCreateDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitCreateDialog()">{{
              $t("btnCreate")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showEditDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("DispatcherList.dialogEditTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formEdit">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DispatcherList.email')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.email"
                    required
                    disabled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox
                    dense
                    :label="$t('DispatcherList.activeAcc')"
                    filled
                    v-model="editDialogData.activeAcc"
                    required
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DispatcherList.firstName')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.firstName"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DispatcherList.lastName')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.lastName"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    prepend-inner-icon="fa-phone"
                    :label="$t('DispatcherList.phone')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.phone"
                    :placeholder="$t('phone_placeholder')"
                    :hint="$t('phone_hint')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="showEditDialog = false">
              {{ $t("btnClose") }}</v-btn
            >
            <v-btn color="success" text @click.stop="submitEditDialog()">{{
              $t("btnSave")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialog zmenu hesla -->
    <v-dialog v-model="showEditPasswordDialog" max-width="600px">
      <v-card
        ><v-form ref="formEditPassword">
          <v-card-title style="word-break: normal">
            {{ $t("DispatcherList.dialogChangePasswordTitle") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12"
                  >{{ $t("DispatcherList.dialogChangePasswordText") }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" v-show="displayOnlyForAdmin">
                  <v-text-field
                    :label="$t('DispatcherList.id')"
                    v-model="editPasswordDialogData.item.id"
                    disabled
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    :label="$t('DispatcherList.password')"
                    prepend-inner-icon="fa-lock"
                    filled
                    :rules="passwordRules"
                    v-model="editPasswordDialogData.item.password"
                    required
                    :append-icon="
                      editPasswordDialogData.showPassword
                        ? 'mdi-eye'
                        : 'mdi-eye-off'
                    "
                    :type="
                      editPasswordDialogData.showPassword ? 'text' : 'password'
                    "
                    append-outer-icon="fa-random"
                    @click:append="
                      editPasswordDialogData.showPassword =
                        !editPasswordDialogData.showPassword
                    "
                    @click:append-outer="
                      (editPasswordDialogData.item.password =
                        Math.random().toString(36).substr(2, 3).toUpperCase() +
                        'x' +
                        Math.random().toString(36).substr(2, 5)),
                        (editPasswordDialogData.showPassword = true)
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="submitChangePassword()">
              {{ $t("btnChange") }}
            </v-btn>
            <v-btn color="error" text @click="showEditPasswordDialog = false">
              {{ $t("btnClose") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Dialog zmenu role -->
    <v-dialog v-model="showEditRoleDialog" max-width="600px">
      <v-card
        ><v-form ref="formEditRole">
          <v-card-title style="word-break: normal">
            {{ $t("DispatcherList.dialogEditRoleTitle") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" v-show="displayOnlyForAdmin">
                  <v-text-field
                    :label="$t('DispatcherList.dispatcherId')"
                    v-model="editPasswordDialogData.dispatcherId"
                    disabled
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col v-if="displayOnlyForAdmin" cols="12">
                  <v-select
                    v-model="editRoleDialogData.role"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    :label="$t('DispatcherList.role')"
                    required
                    filled
                    :items="$t('DispatcherRoleArray')"
                  ></v-select>
                </v-col>
                <v-col v-if="displayOnlyForOwner" cols="12">
                  <!-- <v-select
                    v-model="editRoleDialogData.role"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    :label="$t('DispatcherList.role')"
                    required
                    filled
                    :items="Object.keys(roleEnumForOwner)"
                  ></v-select> -->
                  <v-select
                    v-model="editRoleDialogData.role"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    :label="$t('DispatcherList.role')"
                    required
                    filled
                    :items="$t('DispatcherRoleForOwnerArray')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="submitChangeRole()">
              {{ $t("btnChange") }}
            </v-btn>
            <v-btn color="error" text @click="showEditRoleDialog = false">
              {{ $t("btnClose") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

import { Role, RoleForOwner } from "../enums/RoleEnum";

export default {
  data() {
    return {
      roleEnum: Role,
      roleEnumForOwner: RoleForOwner,
      displayOnlyForAdmin: false,
      displayOnlyForOwner: false,

      moment: moment,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      gridHeaders: [
        {
          text: this.$t("DispatcherList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("DispatcherList.tableHeaders.activeAcc"),
          value: "activeAcc",
        },
        {
          text: this.$t("DispatcherList.tableHeaders.email"),
          value: "email",
          sortable: true,
          filter: (value) => {
            if (!this.filter.email) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.email.toLowerCase());
            }
          },
        },
        {
          text: this.$t("DispatcherList.tableHeaders.firstName"),
          value: "firstName",
          sortable: true,
          filter: (value) => {
            if (!this.filter.firstName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.firstName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("DispatcherList.tableHeaders.lastName"),
          value: "lastName",
          sortable: true,
          filter: (value) => {
            if (!this.filter.lastName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.lastName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("DispatcherList.tableHeaders.phone"),
          value: "phone",
          sortable: true,
          filter: (value) => {
            if (!this.filter.phone) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.phone.toLowerCase());
            }
          },
        },
        { text: this.$t("DispatcherList.tableHeaders.role"), value: "role" },
      ],
      gridItems: [],

      filter: {
        search: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
      },

      loadingData: false,

      createDefaultItem: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        password: "",
      },

      createDialogData: {
        showPassword: false,
        item: {},
      },

      showCreateDialog: false,

      //Dialogove okno s Editovanim polozky
      editDialogData: {},
      showEditDialog: false,

      //Dialogove okno so zmenou hesla
      editDefaultPasswordItem: {
        id: "",
        password: "",
      },
      editPasswordDialogData: {
        showPassword: false,
        item: {
          id: null,
          password: null,
        },
      },
      showEditPasswordDialog: false,

      //Validacia hesla
      passwordRules: [
        (v) => !!v || this.$t("msgRequiredField"),
        (v) => v.length >= 6 || this.$t("msgPasswordMinLength"),
        (v) => /[a-z]/.test(v) || this.$t("msgPasswordLowerCase"),
        (v) => /[0-9]/.test(v) || this.$t("msgPasswordNumber"),
      ],

      //Dialogove okno so zmenou role
      showEditRoleDialog: false,
      editDefaultRoleItem: {
        dispatcherId: "",
        role: "",
      },
      editRoleDialogData: {
        dispatcherId: null,
        role: null,
      },

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  mounted() {
    this.getUserRole();
    this.getAll();
  },
  watch: {
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },
  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
      this.displayOnlyForOwner = userRoles.includes("OWNER");
    },
    getAll() {
      this.loadingData = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/dispatcher`)
        .then((response) => {
          this.loadingData = false;
          //remove from response data objects with role ADMIN and SUPPORT
          if (this.displayOnlyForAdmin) {
            this.gridItems = response.data;
          } else {
            this.gridItems = response.data.filter(
              (item) => item.role !== "ADMIN" && item.role !== "SUPPORT"
            );
          }
          //this.gridItems = response.data;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    detail(id) {
      this.$router.push({ name: "pricelist-detail", params: { id: id } });
    },

    openCreateDialog() {
      this.createDialogData.item = Object.assign({}, this.createDefaultItem);
      this.showCreateDialog = true;
      if (this.$refs.formCreate) {
        this.$refs.formCreate.resetValidation();
      }
      this.createDialogData.showPassword = false;
    },
    submitCreateDialog() {
      const isValid = this.$refs.formCreate.validate();
      if (isValid) {
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/dispatcher`,
            this.createDialogData.item
          )
          .then((response) => {
            this.showCreateDialog = false;
            //this.detail(response.data.id);
            this.getAll(); // zatial len refresh nemame detail
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgCreateError");
            this.snackbar.color = "error";
          });
      }
    },

    openEditDialog(id) {
      if (this.$refs.formEdit) {
        this.$refs.formEdit.resetValidation();
      }
      var gridItems;
      var selectedItem;
      gridItems = this.gridItems;
      selectedItem = Object.assign(
        {},
        gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      console.log("selectedItem", selectedItem);
      if (selectedItem) {
        this.editDialogData = selectedItem;
        this.showEditDialog = true;
      } else {
        console.log("viewLocalityOnDialogMap missing data problem");
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgDataMissing");
        this.snackbar.color = "error";
      }
    },

    submitEditDialog() {
      const isValid = this.$refs.formEdit.validate();
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/dispatcher`,
            this.editDialogData
          )
          .then((response) => {
            this.showEditDialog = false;
            //this.detail(response.data.id);
            this.getAll(); // zatial len refresh nemame detail
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      }
    },

    openEditPasswordDialog(id) {
      this.editPasswordDialogData.item = Object.assign(
        {},
        this.editDefaultPasswordItem
      );
      this.editPasswordDialogData.item.id = id;
      this.showEditPasswordDialog = true;
      if (this.$refs.formEditPassword) {
        this.$refs.formEditPassword.resetValidation();
      }

      this.editPasswordDialogData.showPassword = false;
    },

    submitChangePassword() {
      const isValid = this.$refs.formEditPassword.validate();
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/dispatcher/password`,
            {
              dispatcherId: this.editPasswordDialogData.item.id,
              password: this.editPasswordDialogData.item.password,
            }
          )
          .then((response) => {
            this.showEditPasswordDialog = false;
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "DispatcherList.msgPasswordChanged"
            );
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      } else {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("DispatcherList.msgInvalidDataInForm");
        this.snackbar.color = "error";
      }
    },
    openEditRoleDialog(id) {
      this.editRoleDialogData = Object.assign({}, this.editDefaultRoleItem);
      this.editRoleDialogData.dispatcherId = id;
      this.showEditRoleDialog = true;
      if (this.$refs.formEditRole) {
        this.$refs.formEditRole.resetValidation();
      }
    },
    submitChangeRole() {
      const isValid = this.$refs.formEditRole.validate();
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/dispatcher/role`,
            this.editRoleDialogData
          )
          .then((response) => {
            this.showEditRoleDialog = false;
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "DispatcherList.msgDispatcherRoleUpdated"
            );
            this.snackbar.color = "success";
            this.getAll();
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      } else {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("DispatcherList.msgInvalidDataInForm");
        this.snackbar.color = "error";
      }
    },
  },
};
</script>
